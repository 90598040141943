
.content-margin {

    margin-bottom: rem( 72px );

    @media (max-width: theme.settings.breakpoints.large) {
        margin-bottom: rem( 40px );
    }

}

.content-margin-medium {

    margin-bottom: rem( 36px );

    @media (max-width: theme.settings.breakpoints.large) {
        margin-bottom: rem( 28px );
    }

}

.content-margin-small {

    margin-bottom: rem( 24px );

    @media (max-width: theme.settings.breakpoints.large) {
        margin-bottom: rem( 16px );
    }

}