.HotSpotQuiz {
    position: relative;
    display: inline-block;


    .Image {
        margin-bottom: 0px;
    }

    > .hotSpotAreas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .hotSpotArea {
            &.-show-correctness.-selected {
                background: sm-green-transparent;
                border-color: sm-green;
            }
            &.-show-correctness:not(.-selected) {
                background: sm-red-transparent;
                border-color: sm-red;
            }
        }

    }

    > .spots {

        .spot {
            position: absolute;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 2px solid action-color;
            width: 1.9rem;
            height: 1.9rem;

            &.-show-correctness.-in-area {
                background: sm-green-transparent;
                border: 2px solid sm-green;
            }
            &.-show-correctness:not(.-in-area) {
                background: sm-red-transparent;
                border: 2px solid sm-red;
            }


        }

    }

    

}