.Image {

    > img {
        max-width: 100%;
    }

    > .caption {
        margin-top: rem( 10px );
        text-align: center;
    }
}