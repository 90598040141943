.ResourceDetail {
    
    > .cover {

        width: 100%;
        height: rem( 500px );
        background-size: cover;
        background-position: center;

        @media (max-width: theme.settings.breakpoints.large) {
            height: rem( 300px );
        }

        @media (max-width: theme.settings.breakpoints.medium) {
            height: rem( 200px );
        }

    }

    > .resource-title {
        margin-top: rem( 80px );

        @media (max-width: theme.settings.breakpoints.large) {
            margin-top: rem( 40px );
        }
    }

    > .content-element {
        
        > .meta-info {

            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: 1px solid lighten(sm-dark-gray, 50%);
            border-top: 1px solid lighten(sm-dark-gray, 50%);
            padding: rem(5px) rem( 3px);
            margin-top: rem(15px);
            margin-bottom: rem(30px);

            > .duration {

                margin-top: rem( 7px );
                margin-bottom: rem( 7px );
                display: flex;
                align-items: center;
                justify-content: center;
                
                > svg {
                    margin-right: rem( 7px );
                }                

            }

            > .level, .topic {
                
                display: flex;
                flex-flow: wrap;
                justify-content: flex-end;
                
                > div {
                    border-radius: rem( 3px );
                    background-color: sm-gray;
                    padding: rem( 4px );
                    margin-top: rem( 7px );
                    margin-bottom: rem( 7px );
                    margin-left: rem( 7px );
                }
            }

        }
    }

    > .resource-description {
        margin-bottom: rem( 60px );
    }


}