.coming-soon {
    max-width: rem( 500px );
    text-align: center;
    padding: rem( 15px );
    margin: rem( 30px ) auto;
    border-radius: rem( 5px);
    background-color: gray;

    > .title {
        margin-bottom: rem( 15px );
    }
}