.ChoiceQuiz .TextChoice {

    display: flex;
    align-items: center;
    margin-bottom: rem( 20px );

    > .toggle {
        height: rem( 24px );
        width: @height;
        fill: action-color;
    }

    > .label {
        padding-left: rem( 15px );
    }

}