.DragAndDropQuiz {


    .TextItem {
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;

        &:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }

    > .dropAreas {
        &:first-child {
              flex: 0 0 100%;
            max-width: 100%;
        }
    }

}