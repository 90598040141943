.LocaleLink {
  text-transform: uppercase;
  color: sm-dark-gray;
  transition: all .2s ease-in-out;
  display: inline-block;
  border-bottom: 1px solid transparent;


  &.-active {
    color: action-color;
    font-weight: bold;
    transform: scale(1.2);
    border-bottom: 1px solid action-color;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.LocaleLink + .LocaleLink {
  margin-left: rem(10px);
}
