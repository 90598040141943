.action-button {
    background: white;
    color: action-color;
    border: 2px solid action-color;
    border-radius: 6px;
    height: rem( 41px); 
    text-transform: uppercase;
    padding:  rem( 12px) rem( 17px );
    cursor: pointer;

    &:hover {
        background: action-color; 
        color: white;
        border: 2px solid transparent;
    }
}