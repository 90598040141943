.App {


  > .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: rem(60px);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(2px) rem(16px);
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.30);

    > .home-button {
      width: rem( 38px);
      height: rem( 35px);

      > a > svg  {
        &:hover {
          > g > path {
            stroke: action-color;
          }
        }
      }

        
    }

    > .application-title {
      padding-bottom: rem(10px);

      > h3 {
        padding-top: rem(10px);
        line-height 1;
      }

      > .claim {
        line-height: 1;
      }
    }
  }

  > .header > .icons {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  > .content {
    min-height: calc(100vh - 180px);
    margin-top: rem(60px);

    > #home {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  > .tothetop {
    display: flex;
    justify-content: center;
    margin-top: rem( 60px );
    margin-bottom: rem( 60px );
  }

  > .footer {
    height: rem(120px);
    padding: rem(16px);
    background-color: brand-color;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .singlepage-links {
      list-style: none;
      > li {
        padding: rem( 7px ) 0;
      }
    }
  }


}