.content-element {
    max-width: rem( 1248px );
    margin: auto;
    padding-left: rem(30px);
    padding-right: rem(30px);

    @media (max-width: theme.settings.breakpoints.large) {
        padding-left: rem(20px);
        padding-right: rem(20px);
    } 

    @media (max-width: theme.settings.breakpoints.medium) {
        padding-left: rem(10px);
        padding-right: rem(10px);
    } 
}