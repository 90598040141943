.VideoHosted {

    > video {
        width: 100%;
        max-height: rem( 450px );
    }

    > .caption {
        margin-top: rem( 10px );
        text-align: center;
    }
    
}