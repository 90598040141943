.VideoLinked {

    > iframe {
        width: 100%;
        min-height: rem(450px);
    }

    > .caption {
        margin-top: rem( 10px );
        text-align: center;
    }

 }