@import url("//hello.myfonts.net/count/37a998");


@font-face {font-family: 'Gelion'; font-weight: 700; src: url('fonts/37A998_0_0.eot');src: url('fonts/37A998_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/37A998_0_0.woff2') format('woff2'),url('fonts/37A998_0_0.woff') format('woff'),url('fonts/37A998_0_0.ttf') format('truetype');}


@font-face {font-family: 'Gelion'; font-weight: 400; src: url('fonts/37A998_1_0.eot');src: url('fonts/37A998_1_0.eot?#iefix') format('embedded-opentype'),url('fonts/37A998_1_0.woff2') format('woff2'),url('fonts/37A998_1_0.woff') format('woff'),url('fonts/37A998_1_0.ttf') format('truetype');}


// NOTE: `stylus/` is provided by the `@lernetz/css-lib` NPM package.

// Some base settings as required by `css-lib`'s reset (imported below):
theme = {
  colors: {
    action: #c40,
    body: #fff,
    text: #000,
  },
  components: {

  },
  settings: {
    heading-font: 'foco'\, Arial\, sans-serif,
    text-font: Gelion\, Arial\, sans-serif,
    text-line-height: 100%,
    text-rendering: optimizeLegibility,
    breakpoints: {
      small: rem( 620px ),
      medium: rem( 960px ),
      large: rem( 1280px )
    },
    padding: rem( 15px ),
    margin: rem( 10px )
  },
}



@import "variables.styl";

// Bring in the reset & common functions:
@require 'stylus/shared/*.styl'

// Bring in styling for lernfragen components:

// Configure CSS components (ours override `css-lib`'s):
@require 'stylus/components/**/*.var.styl';
// @require './components/**/*.var.styl';

// Bring in required CSS components:
@require 'stylus/components/text/text-style.styl';
@require 'stylus/components/layout/grid-layout.styl';
@require './components/*.styl';
@require './helpers/*.styl';
// @require './components/**/*.styl';

// Bring in styling for application-specific components:
@require '../typescript/components/**/*.styl';
