.DraggableTextItem {

    background-color: white;
    padding: rem(4px);
    border: 1px solid sm-gray2;
    border-radius: rem(3px);
    display: flex;
    margin: rem(10px);
    max-width: rem(350px);

    &:hover {
        color: action-color;
        border: 1px solid action-color;
        cursor: grab;
    }

    > .image {
        max-width: rem(160px);
        max-height: rem(106px);
        float: left;
    }

    > .text {
        padding-left: rem(8px);
        padding-right: rem(8px);
        align-self: center;
    }

    &.-show-correctness:not(.-in-correct-drop-area) {
        background: sm-red-transparent;
        border: 1px solid sm-red;
        color: sm-red;
    }

    &.-show-correctness.-in-correct-drop-area {
        background: sm-green-transparent;
        border: 1px solid sm-green;
        color: sm-green;
    }


}