.underline-link {
    position: relative;
    color: white;
    text-decoration: none;
    cursor: pointer;

    > .link {
        color: white;
    }

    > .link:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        margin-bottom: rem( 5px );
        background-color: white;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    > .link:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

}

.underline-link:hover {
    color: white;
}