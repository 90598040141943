.ClozeQuiz {

    .paragraph {
        margin-bottom: rem( 20px );
    }

    .choices {
        &.-show-correctness {
            background: sm-light-red;
            border-color: sm-red;
            color: sm-red;
        }
        &.-show-correctness.-correct{
            background: sm-light-green;
            border-color: sm-green;
            color: sm-green;
        }
    }


}