.DropArea {

  &:first-child {
    width: 100%;
  }  
    
}

.DropArea.-labelled {

  position: relative;
  flex-basis: 0;
  flex: 1 1 0;
  min-width: 31%;

  > .label {
    position: relative;
    z-index: 1;
    color: sm-text-gray;
    font-size: 120%;
  }

  > .items {
    z-index: 2;
    height: 100%;
  }
}