.Filter {
    @media (max-width: theme.settings.breakpoints.large) {
        display: none;
    }
}

.Filter, .FilterMobile {

    margin-left: rem(15px);
    padding: theme.settings.padding;
    padding-top: rem( 25px);
    max-width: rem( 248px );
    min-width: rem( 248px );
    background-color: sm-gray;

    > h3 {
        text-transform: uppercase;
    }

    > .filtertitle {
        border-bottom: 1px sm-gray2 solid; 
        margin-bottom: rem( 7px );
        padding-top: rem( 30px );
        text-transform: none;
    }


}