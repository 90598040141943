.QuizPresenter {

     > .feedback {
         background-color: sm-gray;
         padding: rem(15px);

         &.-show {
            display: flex;
         }

         > .quiz-status {
             margin-right: rem(23px);
             width: rem(40px);
             height: rem(40px);
         }
     }

     > .action-button {
         display: inherit;
         
     }
}