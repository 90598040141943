@import url("//hello.myfonts.net/count/37a998");
@font-face {
  font-family: 'Gelion';
  font-weight: 700;
  src: url("fonts/37A998_0_0.eot");
  src: url("fonts/37A998_0_0.eot?#iefix") format('embedded-opentype'), url("fonts/37A998_0_0.woff2") format('woff2'), url("fonts/37A998_0_0.woff") format('woff'), url("fonts/37A998_0_0.ttf") format('truetype');
}
@font-face {
  font-family: 'Gelion';
  font-weight: 400;
  src: url("fonts/37A998_1_0.eot");
  src: url("fonts/37A998_1_0.eot?#iefix") format('embedded-opentype'), url("fonts/37A998_1_0.woff2") format('woff2'), url("fonts/37A998_1_0.woff") format('woff'), url("fonts/37A998_1_0.ttf") format('truetype');
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 100%;
  color: #000;
  font-family: Gelion , Arial , sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #c40;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: .text-style
category: Text
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
.text-style.-h1 {
  font-family: 'foco' , Arial , sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.text-style.-h2 {
  font-family: 'foco' , Arial , sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.text-style.-h3 {
  font-family: 'foco' , Arial , sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-style.-center {
  text-align: center;
}
.text-style.-right {
  text-align: right;
}
.text-style.-bold {
  font-weight: 700;
}
.text-style.-medium {
  font-weight: 500;
}
.text-style.-inverted {
  color: ;
}
.text-style.-indent-1 {
  padding-left: 1.875rem;
}
.text-style.-indent-2 {
  padding-left: 3.75rem;
}
.text-style.-indent-3 {
  padding-left: 5.625rem;
}
.text-style.-indent-4 {
  padding-left: 7.5rem;
}
.text-style.-indent-5 {
  padding-left: 9.375rem;
}
.text-style.-indent-6 {
  padding-left: 11.25rem;
}
/*
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (min-width: 38.75rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 60rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 80rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
.action-button {
  background: #fff;
  color: #3992eb;
  border: 2px solid #3992eb;
  border-radius: 6px;
  height: 2.5625rem;
  text-transform: uppercase;
  padding: 0.75rem 1.0625rem;
  cursor: pointer;
}
.action-button:hover {
  background: #3992eb;
  color: #fff;
  border: 2px solid transparent;
}
.coming-soon {
  max-width: 31.25rem;
  text-align: center;
  padding: 0.9375rem;
  margin: 1.875rem auto;
  border-radius: 0.3125rem;
  background-color: #808080;
}
.coming-soon > .title {
  margin-bottom: 0.9375rem;
}
.content-element {
  max-width: 78rem;
  margin: auto;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
@media (max-width: 80rem) {
  .content-element {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (max-width: 60rem) {
  .content-element {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.content-margin {
  margin-bottom: 4.5rem;
}
@media (max-width: 80rem) {
  .content-margin {
    margin-bottom: 2.5rem;
  }
}
.content-margin-medium {
  margin-bottom: 2.25rem;
}
@media (max-width: 80rem) {
  .content-margin-medium {
    margin-bottom: 1.75rem;
  }
}
.content-margin-small {
  margin-bottom: 1.5rem;
}
@media (max-width: 80rem) {
  .content-margin-small {
    margin-bottom: 1rem;
  }
}
h1 {
  font-family: 'foco' , Arial , sans-serif;
  font-weight: bold;
  font-size: 4.0625rem;
  line-height: 4.1875rem;
  letter-spacing: 0.0375rem;
}
h2 {
  font-family: 'foco' , Arial , sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.125rem;
  letter-spacing: 0.0275rem;
}
h3 {
  font-family: 'foco' , Arial , sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.125rem;
  letter-spacing: 0.016875rem;
}
h3.title {
  line-height: 1.75rem;
}
h4.claim {
  font-family: 'foco' , Arial , sans-serif;
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 2.125rem;
  letter-spacing: 0.016875rem;
}
p.feedback {
  font-family: 'foco' , Arial , sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
p {
  font-family: Gelion , Arial , sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
p.description {
  line-height: 1.4375rem;
}
@media (max-width: 60rem) {
  h1 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h3.title {
    line-height: 1.5rem;
  }
  h4.claim {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.625rem;
  }
  p.description {
    line-height: 1.1875rem;
  }
}
.underline-link {
  position: relative;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.underline-link > .link {
  color: #fff;
}
.underline-link > .link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  margin-bottom: 0.3125rem;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.underline-link > .link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.underline-link:hover {
  color: #fff;
}
.-hidden {
  display: none;
}
.App > .header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3.75rem;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.125rem 1rem;
  background-color: rgba(255,255,255,0.95);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}
.App > .header > .home-button {
  width: 2.375rem;
  height: 2.1875rem;
}
.App > .header > .home-button > a > svg:hover > g > path {
  stroke: #3992eb;
}
.App > .header > .application-title {
  padding-bottom: 0.625rem;
}
.App > .header > .application-title > h3 {
  padding-top: 0.625rem;
  line-height: 1;
}
.App > .header > .application-title > .claim {
  line-height: 1;
}
.App > .header > .icons {
  display: flex;
  justify-content: left;
  align-items: center;
}
.App > .content {
  min-height: calc(100vh - 180px);
  margin-top: 3.75rem;
}
.App > .content > #home {
  position: absolute;
  top: 10px;
  left: 0;
}
.App > .tothetop {
  display: flex;
  justify-content: center;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
.App > .footer {
  height: 7.5rem;
  padding: 1rem;
  background-color: #3385d6;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.App > .footer > .singlepage-links {
  list-style: none;
}
.App > .footer > .singlepage-links > li {
  padding: 0.4375rem 0;
}
.LocaleLink {
  text-transform: uppercase;
  color: #070707;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  border-bottom: 1px solid transparent;
}
.LocaleLink.-active {
  color: #3992eb;
  font-weight: bold;
  transform: scale(1.2);
  border-bottom: 1px solid #3992eb;
}
.LocaleLink:hover {
  transform: scale(1.2);
}
.LocaleLink + .LocaleLink {
  margin-left: 0.625rem;
}
.IFrame > iframe {
  width: 100%;
  height: 43.125rem;
}
.IFrame > .caption {
  margin-top: 0.625rem;
  text-align: center;
}
.Image > img {
  max-width: 100%;
}
.Image > .caption {
  margin-top: 0.625rem;
  text-align: center;
}
.ToolTipArea {
  position: absolute;
  background: transparent;
  color: #fff;
}
.ToolTipElement {
  background-color: rgba(0,78,189,0.5);
  border: 1px solid #838383;
  border-radius: 0.1875rem;
  padding: 0.75rem;
  color: #fff;
  position: absolute;
  transform: translate(-50%, -100%);
}
.ToolTipElementStage {
  position: relative;
  overflow: visible;
}
.ToolTipElementStage > .image {
  width: 100%;
}
.VideoHosted > video {
  width: 100%;
  max-height: 28.125rem;
}
.VideoHosted > .caption {
  margin-top: 0.625rem;
  text-align: center;
}
.VideoLinked > iframe {
  width: 100%;
  min-height: 28.125rem;
}
.VideoLinked > .caption {
  margin-top: 0.625rem;
  text-align: center;
}
.tag-filter .tag-list {
  display: flex;
  flex-flow: wrap;
}
@media (max-width: 80rem) {
  .Filter {
    display: none;
  }
}
.Filter,
.FilterMobile {
  margin-left: 0.9375rem;
  padding: 0.9375rem;
  padding-top: 1.5625rem;
  max-width: 15.5rem;
  min-width: 15.5rem;
  background-color: #f3f3f2;
}
.Filter > h3,
.FilterMobile > h3 {
  text-transform: uppercase;
}
.Filter > .filtertitle,
.FilterMobile > .filtertitle {
  border-bottom: 1px #838383 solid;
  margin-bottom: 0.4375rem;
  padding-top: 1.875rem;
  text-transform: none;
}
.FilterMobile {
  display: none;
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}
.FilterMobile > .header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.FilterMobile > .header > .arrow {
  display: flex;
  align-items: center;
}
.FilterMobile > .header > .arrow.-closed {
  transform: rotate(180deg);
}
.FilterMobile > .filters.-hide {
  display: none;
}
@media (max-width: 80rem) {
  .FilterMobile {
    display: inline;
  }
}
.pool-container {
  display: flex;
}
@media (max-width: 80rem) {
  .pool-container {
    flex-flow: column;
  }
  .pool-container > .Filter {
    order: 1;
    margin-bottom: 0;
  }
  .pool-container > .Pool {
    order: 2;
    margin: 3.75rem auto;
  }
}
.Pool {
  margin: 6.25rem auto;
  width: 100%;
}
.Pool > .resource-list {
  margin-left: 0;
}
.Pool > .resource-list > li {
  list-style: none;
  margin-bottom: 1.875rem;
}
.ChoiceQuiz ul {
  list-style: none;
  margin-left: 0;
}
.ChoiceQuiz > .choices > .choice .label {
  font-family: Gelion , Arial , sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct.-selected {
  color: #23a33d;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct.-selected .toggle {
  fill: #23a33d;
}
.ChoiceQuiz > .choices > .choice.-show-correctness:not(.-correct):not(.-selected) {
  color: #23a33d;
}
.ChoiceQuiz > .choices > .choice.-show-correctness:not(.-correct):not(.-selected) .toggle {
  fill: #23a33d;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct:not(.-selected) {
  color: #ea5762;
}
.ChoiceQuiz > .choices > .choice.-show-correctness.-correct:not(.-selected) .toggle {
  fill: #ea5762;
}
.ChoiceQuiz > .choices > .choice.-show-correctness:not(.-correct).-selected {
  color: #ea5762;
}
.ChoiceQuiz > .choices > .choice.-show-correctness:not(.-correct).-selected .toggle {
  fill: #ea5762;
}
@media (max-width: 60rem) {
  .ChoiceQuiz > .choices > .choice .label {
    font-size: 1rem;
    line-height: 1.625rem;
  }
}
.ClozeQuiz .paragraph {
  margin-bottom: 1.25rem;
}
.ClozeQuiz .choices.-show-correctness {
  background: #ffe0e2;
  border-color: #ea5762;
  color: #ea5762;
}
.ClozeQuiz .choices.-show-correctness.-correct {
  background: #dcecdc;
  border-color: #23a33d;
  color: #23a33d;
}
.DragAndDropQuiz .TextItem {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.DragAndDropQuiz .TextItem:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.DragAndDropQuiz > .dropAreas:first-child {
  flex: 0 0 100%;
  max-width: 100%;
}
.DraggableTextItem {
  background-color: #fff;
  padding: 0.25rem;
  border: 1px solid #838383;
  border-radius: 0.1875rem;
  display: flex;
  margin: 0.625rem;
  max-width: 21.875rem;
}
.DraggableTextItem:hover {
  color: #3992eb;
  border: 1px solid #3992eb;
  cursor: grab;
}
.DraggableTextItem > .image {
  max-width: 10rem;
  max-height: 6.625rem;
  float: left;
}
.DraggableTextItem > .text {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-self: center;
}
.DraggableTextItem.-show-correctness:not(.-in-correct-drop-area) {
  background: rgba(227,0,26,0.2);
  border: 1px solid #ea5762;
  color: #ea5762;
}
.DraggableTextItem.-show-correctness.-in-correct-drop-area {
  background: rgba(35,163,61,0.2);
  border: 1px solid #23a33d;
  color: #23a33d;
}
.DropArea {
  margin: 0.5em;
  padding: 1em 1em;
  background-color: #f3f3f2;
  min-height: 15.625rem;
}
.DropArea > .items {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  min-height: 3.125rem;
  min-width: 3.125rem;
}
.HotSpotQuiz {
  position: relative;
  display: inline-block;
}
.HotSpotQuiz .Image {
  margin-bottom: 0px;
}
.HotSpotQuiz > .hotSpotAreas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness.-selected {
  background: rgba(35,163,61,0.2);
  border-color: #23a33d;
}
.HotSpotQuiz > .hotSpotAreas .hotSpotArea.-show-correctness:not(.-selected) {
  background: rgba(227,0,26,0.2);
  border-color: #ea5762;
}
.HotSpotQuiz > .spots .spot {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid #3992eb;
  width: 1.9rem;
  height: 1.9rem;
}
.HotSpotQuiz > .spots .spot.-show-correctness.-in-area {
  background: rgba(35,163,61,0.2);
  border: 2px solid #23a33d;
}
.HotSpotQuiz > .spots .spot.-show-correctness:not(.-in-area) {
  background: rgba(227,0,26,0.2);
  border: 2px solid #ea5762;
}
.DropArea:first-child {
  width: 100%;
}
.DropArea.-labelled {
  position: relative;
  flex-basis: 0;
  flex: 1 1 0;
  min-width: 31%;
}
.DropArea.-labelled > .label {
  position: relative;
  z-index: 1;
  color: #828282;
  font-size: 120%;
}
.DropArea.-labelled > .items {
  z-index: 2;
  height: 100%;
}
.QuizPresenter > .feedback {
  background-color: #f3f3f2;
  padding: 0.9375rem;
}
.QuizPresenter > .feedback.-show {
  display: flex;
}
.QuizPresenter > .feedback > .quiz-status {
  margin-right: 1.4375rem;
  width: 2.5rem;
  height: 2.5rem;
}
.QuizPresenter > .action-button {
  display: inherit;
}
.ChoiceQuiz .TextChoice {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.ChoiceQuiz .TextChoice > .toggle {
  height: 1.5rem;
  width: 1.5rem;
  fill: #3992eb;
}
.ChoiceQuiz .TextChoice > .label {
  padding-left: 0.9375rem;
}
.Resource {
  margin: 0.625rem;
  box-shadow: 0 2px 6px 0 rgba(107,107,107,0.2);
  border-radius: 2px;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.Resource > .previewimage {
  width: 100%;
  height: 8.125rem;
  background-position: center;
  background-size: cover;
}
.Resource > .content {
  padding: 0.9375rem;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}
.Resource > .content > .meta-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #838383;
  padding: 0.3125rem 0;
}
.Resource > .content > .meta-container > .duration {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Resource > .content > .meta-container > .duration > svg {
  margin-right: 0.4375rem;
}
.Resource > .content > .meta-container > .level {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
}
.Resource > .content > .meta-container > .level > div {
  border-radius: 0.1875rem;
  background-color: #f3f3f2;
  padding: 0.25rem;
  margin-left: 0.4375rem;
  margin-bottom: 0.4375rem;
}
.Resource > .content > .title,
.Resource > .content .description {
  padding-top: 0.625rem;
}
.Resource > .content > .description {
  flex-grow: 1;
}
.Resource > .content > .open-button {
  padding-top: 0.9375rem;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
}
.Resource > .content > .open-button > a {
  border-bottom: 1px solid transparent;
  color: #070707;
}
.Resource > .content > .open-button:hover > a {
  color: #3992eb;
  border-bottom: 1px solid #3992eb;
}
.Resource:hover {
  box-shadow: 0 2px 16px 1px rgba(107,107,107,0.3);
}
.ResourceDetail > .cover {
  width: 100%;
  height: 31.25rem;
  background-size: cover;
  background-position: center;
}
@media (max-width: 80rem) {
  .ResourceDetail > .cover {
    height: 18.75rem;
  }
}
@media (max-width: 60rem) {
  .ResourceDetail > .cover {
    height: 12.5rem;
  }
}
.ResourceDetail > .resource-title {
  margin-top: 5rem;
}
@media (max-width: 80rem) {
  .ResourceDetail > .resource-title {
    margin-top: 2.5rem;
  }
}
.ResourceDetail > .content-element > .meta-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #838383;
  border-top: 1px solid #838383;
  padding: 0.3125rem 0.1875rem;
  margin-top: 0.9375rem;
  margin-bottom: 1.875rem;
}
.ResourceDetail > .content-element > .meta-info > .duration {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ResourceDetail > .content-element > .meta-info > .duration > svg {
  margin-right: 0.4375rem;
}
.ResourceDetail > .content-element > .meta-info > .level,
.ResourceDetail > .content-element > .meta-info .topic {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
}
.ResourceDetail > .content-element > .meta-info > .level > div,
.ResourceDetail > .content-element > .meta-info .topic > div {
  border-radius: 0.1875rem;
  background-color: #f3f3f2;
  padding: 0.25rem;
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
  margin-left: 0.4375rem;
}
.ResourceDetail > .resource-description {
  margin-bottom: 3.75rem;
}
.SinglePage {
  padding-top: 6.25rem;
}
.Tag {
  display: flex;
  flex-flow: wrap;
}
.Tag.filter-button {
  margin-top: 0.3125rem;
  margin-right: 0.3125rem;
  padding: 0.625rem;
  border: 1px #070707 solid;
  border-radius: 0.1875rem;
  cursor: pointer;
}
.Tag.filter-button:hover,
.Tag.filter-button.-active {
  background-color: #3992eb;
  border: 1px transparent solid;
  color: #fff;
}
.Placeholder {
  display: inline-block;
  background-color: #eee;
  border: dashed 0.0625rem #888;
  color: #888;
  padding: 0.625rem 0.9375rem;
  margin: 0.75rem 0 0.75rem;
}
.Placeholder:after {
  display: inline;
  content: ' (not implemented)';
}
