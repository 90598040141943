.DropArea {
  margin: 0.5em;
  padding: 1em 1em;
  background-color: sm-gray;
  min-height: rem(250px);

  > .items {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    min-height: rem(50px);
    min-width: rem(50px);
  }
}