.Tag {

    display: flex;
    flex-flow: wrap;

    &.filter-button {

        margin-top: rem( 5px );
        margin-right: rem( 5px );
        padding: rem( 10px );
        border: 1px sm-dark-gray solid;
        border-radius: rem( 3px );
        cursor: pointer;

        &:hover, &.-active {
            background-color: action-color; 
            border: 1px transparent solid;
            color: white;
        }

    }

}
