.tag-filter {

    .tag-list {

        display: flex;
        flex-flow: wrap;


    }
    
} 