h1 {
  font-family: theme.settings.heading-font;
  font-weight: bold;
  font-size: rem(65px);
  line-height: rem(67px);
  letter-spacing: rem(0.6px);
}

h2 {
  font-family: theme.settings.heading-font;
  font-weight: bold;
  font-size: rem(40px);
  line-height: rem(50px);
  letter-spacing: rem(0.44px);
}

h3 {
  font-family: theme.settings.heading-font;
  font-weight: bold;
  font-size: rem(24px);
  line-height: rem(34px);
  letter-spacing: rem(0.27px);
}

h3.title {
  line-height: rem(28px);
}

h4.claim {
  font-family: theme.settings.heading-font;
  font-weight: 100;
  font-size: rem(24px);
  line-height: rem(34px);
  letter-spacing: rem(0.27px);
}

p.feedback {
  font-family: theme.settings.heading-font;
  font-weight: bold;
  font-size: rem(20px);
  line-height: rem(30px);
}

p {
  font-family: theme.settings.text-font;
  font-size: rem(20px);
  line-height: rem(30px);
}

p.description {
  line-height: rem(23px);
}

@media (max-width: theme.settings.breakpoints.medium) {
  h1 {
    font-size: rem(45px);
    line-height: rem(55px);
  }

  h2 {
    font-size: rem(28px);
    line-height: rem(38px);
  }

  h3 {
    font-size: rem(20px);
    line-height: rem(30px);
  }

  h3.title {
    line-height: rem(24px);
  }

  h4.claim {
    font-size: rem(20px);
    line-height: rem(30px);
  }

  p {
    font-size: rem(16px);
    line-height: rem(26px);
  }

  p.description {
    line-height: rem(19px);
  }
}