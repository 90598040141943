.ChoiceQuiz {

    ul {
        list-style: none;
        margin-left: 0;
    }

    > .choices {

        > .choice {

            .label {
                font-family: theme.settings.text-font;
                font-size: rem(20px);
                line-height: rem(30px);
            }

            &.-show-correctness.-correct.-selected {
                color: sm-green;

                .toggle {
                    fill: sm-green;
                }
            }
            &.-show-correctness:not(.-correct):not(.-selected) {
                color: sm-green;
                .toggle {
                    fill: sm-green;
                }
            }
            &.-show-correctness.-correct:not(.-selected) {
                color: sm-red;
                .toggle {
                    fill: sm-red;
                }
            }
            &.-show-correctness:not(.-correct).-selected {
                color: sm-red;
                .toggle {
                    fill: sm-red;
                }
            }

        }

    } 

}

@media (max-width: theme.settings.breakpoints.medium) {

    .ChoiceQuiz { 
            
        > .choices {

            > .choice {

                .label {
                    font-size: rem(16px);
                    line-height: rem(26px);
                }

            }
            
        }

    }

}