.FilterMobile {
    display: none;
    width: 100%;
    max-width: 100%;
    margin-left: 0;

    > .header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        > .arrow {
            display: flex;
            align-items: center;

            &.-closed {
                transform:rotate(180deg);
            }
        }
    }

    > .filters {

        &.-hide {
            // transform: translateY(-150%);
            // transition: transform 0.5s ease;
            display: none;
        }

        &.-show {
            // transform: translateY(0);
            // transition: transform 0.5s ease;
        }
    }

    @media (max-width: theme.settings.breakpoints.large) {
        display: inline;
    }
}