.Placeholder {

  display: inline-block;
  background-color: #eee;
  border: dashed rem(1px) #888;
  color: #888;
  padding: rem(10px) rem(15px);
  margin: rem(12px) 0 rem(12px);

  &:after {
    display: inline;
    content: ' (not implemented)';
  }
}
