.IFrame {

    > iframe {
        width: 100%;
        height: rem(690px);
    }

    > .caption {
        margin-top: rem( 10px );
        text-align: center;
    }

 }