.pool-container {
    display: flex;
    
    @media (max-width: theme.settings.breakpoints.large) {

        flex-flow: column;
        > .Filter {
            order: 1;
            margin-bottom: 0;
        }
        > .Pool {
            order: 2;
            margin: rem(60px) auto;
        }
        
    }
}

.Pool {

    margin: rem(100px) auto;
    width: 100%;

    > .resource-list {
        
        margin-left: 0;

        > li {
            list-style: none;
            margin-bottom: rem(30px);
        }

    }

}