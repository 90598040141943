.Resource {

    margin: theme.settings.margin;
    box-shadow: 0 2px 6px 0 rgba(107,107,107,0.20);
    border-radius: 2px;
    transition: transform .2s, box-shadow .2s;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: 100%;

    > .previewimage {
        width: 100%;
        height: rem( 130px );
        background-position: center;
        background-size: cover;
    }

    > .content {
        padding: theme.settings.padding;
        flex-grow: 1;
        display: flex;
        flex-flow: column;

        > .meta-container {
            
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: 1px solid lighten(sm-dark-gray, 50%);
            padding: rem(5px) 0;

            > .duration {
                
                > svg {
                    margin-right: rem( 7px );
                }                

                display: flex;
                align-items: center;
                justify-content: center;

            }

            > .level {
                
                display: flex;
                flex-flow: wrap;
                justify-content: flex-end;
                
                > div {
                    border-radius: rem( 3px );
                    background-color: sm-gray;
                    padding: rem( 4px );
                    margin-left: rem( 7px );
                    margin-bottom: rem( 7px );
                }
            }

        }

        > .title, .description {
            padding-top: rem( 10px );
        }

        > .description {
            flex-grow: 1;
        }

        > .open-button {
            padding-top: rem( 15px );
            display: flex;
            justify-content: flex-end;
            text-transform: uppercase;
            
            > a {
                border-bottom: 1px solid transparent;
                color: sm-dark-gray;
            }


            &:hover {
                > a {
                    color: action-color;
                    border-bottom: 1px solid action-color;
                }
            }
        }

    }

    &:hover {
        box-shadow: 0 2px 16px 1px rgba(107,107,107,0.30);
    }

}